import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App'

import './stylesheets/index.scss'

TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM,
})
ReactGA.initialize(process.env.REACT_APP_GA)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <>
        <Router>
            <Route component={App} />
        </Router>
    </>,
)
