import axios from 'axios'

const call = {
    get: (url, data = {}) => {
        return axios({
            method: 'GET',
            url:
                process.env.REACT_APP_ENDPOINT +
                url +
                '?' +
                Object.entries(data)
                    .map(([key, val]) => `${key}=${val}`)
                    .join('&'),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {},
        })
    },
    post: (url, data = {}) => {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_ENDPOINT + url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data,
        })
    },
    download: (url, data = {}) => {
        return axios({
            method: 'POST',
            responseType: 'blob',
            url: process.env.REACT_APP_ENDPOINT + url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data,
        })
    },
}

const api = {
    initialize: () => {
        return call.get('/initialize', {})
    },
    update: (step, payload) => {
        return call.post('/update', { step, ...payload })
    },
    download: (payload) => {
        return call.download('/download', payload)
    },
}

export default api
